import TourCard from "../Components/TourCard/TourCard";
import Container from "../Components/Container/Container";
import Section from "../Components/Section/Section";
import SectionHeading from "../Components/Headings/SectionHeading";
import { fetchAllTours } from "../services/tourServices";
import { useEffect, useState } from "react";

function HomeTours() {
  const [tours, setTours] = useState([]);

  useEffect(() => {
    async function fetchTours() {
      try {
        const data = await fetchAllTours();
        setTours(data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchTours();
  }, []);

  return (
    <Section pt="75px">
      <Container className="row justify-content-between">
        <SectionHeading textAlign="center" mb="50px">
          Best-selling tours
        </SectionHeading>
        <div className="row p-0">
          {tours.map((tour, i) => {
            if (i < 3)
              return (
                <div className="col-12 col-lg-4 p-0">
                  <TourCard
                    url={tour.url}
                    image={tour.image}
                    name={tour.name}
                    duration={tour.duration}
                    location={`${tour.location.country}`}
                    price={tour.price.adults}
                  />
                </div>
              );
          })}
        </div>
      </Container>
    </Section>
  );
}

export default HomeTours;

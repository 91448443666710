import { useEffect, useState } from "react";
import styles from "./Tours.module.css";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import Section from "../Components/Section/Section";
import Container from "../Components/Container/Container";
import TourCard from "../Components/TourCard/TourCard";
import { FaChevronDown } from "react-icons/fa";
import { fetchAllTours } from "../services/tourServices";

export default function Tours() {
  const [tours, setTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchTours() {
      try {
        setIsLoading(true);
        const data = await fetchAllTours();
        console.log(data);
        setTours(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchTours();
  }, []);

  return (
    <Section>
      <Breadcrumb pageName="Tours" />
      <Container py="40px">
        <div className={styles.Title}>
          {isLoading ? <>Loading...</> : <>{tours.length} tours found</>}
        </div>
        <div className="col-12 row p-0">
          {tours.map((tour) => {
            return (
              <div className="col-12 col-lg-4 p-0">
                <TourCard
                  url={tour.url}
                  name={tour.name}
                  image={tour.image}
                  duration={tour.duration}
                  location={`${tour.location.city}, ${tour.location.country}`}
                  price={tour.price.adults}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  );
}

function FilterBox() {
  return (
    <div className={styles.FilterBox}>
      <p className="bold">Filter</p>

      <h6 className={styles.FilterTitle}>
        <span>Price </span>
        <span className={styles.FilterTitleIcon}>
          <FaChevronDown />
        </span>
      </h6>
    </div>
  );
}

import styles from "./Buttons.module.css";

export default function PrimaryButtonOutline(props) {
  const btnStyle = {
    marginRight: props.mr || props.mx,
    marginLeft: props.ml || props.mx,
    marginTop: props.mt || props.my,
    marginBottom: props.mb || props.my,
  };

  return (
    <button className={styles.BtnPrimaryOutline} style={btnStyle}>
      {props.children}
    </button>
  );
}

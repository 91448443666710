import styles from "./Buttons.module.css";

export default function PrimaryButton(props) {
  const btnStyle = {
    marginRight: props.mr || props.mx,
    marginLeft: props.ml || props.mx,
    marginTop: props.mt || props.my,
    marginBottom: props.mb || props.my,
  };

  return (
    <button
      className={`${styles.BtnPrimary} ${props.className}`}
      onClick={props.onClick}
      style={btnStyle}
    >
      {props.children}
    </button>
  );
}
